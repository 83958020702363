import React from 'react'
import '../assets/css/header.css';
import { Link } from "react-router-dom";

export default function header() {

    return (
        <nav className="navbar navbar-light header">
            <div className="container">
                <Link to="/" className="navbar-brand"><img className='logo' src="/assets/img/logo.svg" alt="" /></Link>
            </div>
        </nav>
    )
}